import Clarity from '@microsoft/clarity';

// Import global styles
import './src/styles/variables.scss'
import './src/styles/reset.scss'
import './src/styles/global.scss'
import './src/styles/typography.scss'
import './src/styles/mixins.scss'


export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    Clarity.init("po6apu5fme");
  }
}

// Optional: Performance monitoring or analytics
export const onRouteUpdate = ({ location, prevLocation }) => {
  // Track page views
  // Example with Google Analytics:
  // window.gtag('config', 'YOUR_GA_MEASUREMENT_ID', {
  //   page_path: location.pathname,
  // });
}

// Optional: Wrap root element with providers
export const wrapRootElement = ({ element }) => {
  return (
    // If using context providers, wrap here
    // <SomeProvider>
    //   {element}
    // </SomeProvider>
    element
  )
}

// Optional: Smooth scroll polyfill
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  // Optional custom scroll behavior
  // window.scrollTo(0, 0)
  return true
}
